import * as React from 'react';
import { ThemeProvider, StyledEngineProvider } from './library/pld-ui';
import { ClientTheme, BotConfig } from './global/context';
import { appTheme } from './themes'
import './App.css';
import Routes from './components/Routes'

import { getEnvironmentConfigValue } from './utils';

import { AuthProvider } from './library/pld-auth';

export default function App() {

  let clientTheme = undefined
  let chatMode ='default'
  let quickButtonsStacked = true
  let enableLoader = false


  // Custom attributes which can be passed from root div
  // data-conversation-type > When we want to open direct chat with live agent (available options default/live_agent)
  // data-primary-color > Primary color for chat app
  // data-secondary-color > Secondary color for chat app
  // data-primary-text > Color for primary Text
  // data-font-family > Name of font family, default is Source Sans Pro
  // data-font-size > font size that's required
  // data-border-radius > Required border radius working with mostly outer divs of component 

  const rootDiv = document.getElementById('pldchatbot')

  if (rootDiv) {
    clientTheme = {
      "primaryColor": rootDiv.getAttribute('data-chat-primary-color'),
      "secondaryColor": rootDiv.getAttribute('data-chat-secondary-color'),
      "primaryText": rootDiv.getAttribute('data-chat-primary-text'),
      "fontFamily": rootDiv.getAttribute('data-chat-font-family'),
      "fontSize": rootDiv.getAttribute('data-chat-font-size'),
      "borderRadius": rootDiv.getAttribute('data-chat-border-radius')
    }

    if (rootDiv.getAttribute('data-conversation-type'))
      chatMode = rootDiv.getAttribute('data-conversation-type')

    if (rootDiv.getAttribute('data-widget-stack-quick-action-buttons'))
      quickButtonsStacked = rootDiv.getAttribute('data-widget-stack-quick-action-buttons') === 'true' ? true : false

    if (rootDiv.getAttribute('data-enable-loader'))
      enableLoader = rootDiv.getAttribute('data-enable-loader') === 'true' ? true : false
  }

  const applicationTheme = appTheme()

  let auth_configuration = getEnvironmentConfigValue("auth_config")
  
  return (  
    <AuthProvider configuration={auth_configuration}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={applicationTheme}>
          <ClientTheme.Provider value={clientTheme}>
            <BotConfig.Provider value={{ chatMode, quickButtonsStacked, enableLoader }}>
              <div className="App">
                <Routes />
              </div>
            </BotConfig.Provider>
          </ClientTheme.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </AuthProvider>
  );
}

import React ,{ useEffect, useState } from 'react'
import ChatWidgetV2 from '../components/ChatWidgetV2'
import * as microsoftTeams from "@microsoft/teams-js";
// eslint-disable-next-line
export default function () {

    const [botContext, setBotContext] = useState({})

    const on_context_loaded = React.useCallback( (context) => {
        setBotContext(context);
    }, [setBotContext]);

    useEffect(() => {
        // console.log(window)
        
        try {
            microsoftTeams.initialize();
            microsoftTeams.appInitialization.notifyAppLoaded();
            microsoftTeams.getContext(async context => {
                microsoftTeams.appInitialization.notifySuccess();
            });
            redirectToTeam()
        } catch (err) {
            microsoftTeams.appInitialization.notifyFailure({ reason: microsoftTeams.appInitialization.FailedReason.Other, message: err.message });
        }

        // eslint-disable-next-line
    }, []);
    const redirectToTeam = ()=>{
        
        var url = 'https://teams.microsoft.com/l/entity/ad0c8d6f-39a6-48b5-add7-aa85e3d28236?webUrl=https://prod.chatbot.prologis.com/msteamsapp&label=Support';
        console.log(url);
        microsoftTeams.executeDeepLink(url);
    }
    

    return (
        <div style={{width: '100%'}}>
            <ChatWidgetV2
                defaultOpen={true}
                fullScreenMode={true}
                showCloseButton={false}
            />
        </div>
    )
}

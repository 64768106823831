import React, { useState, useEffect, useRef, useContext } from 'react'
import {
    Widget,
    addResponseMessage,
    addUserMessage,
    setQuickButtons,
    deleteMessages,
    toggleWidget,
    toggleMsgLoader,
    markAllAsRead,
    renderCustomComponent,
    addLinkSnippet,
    isWidgetOpened,
    dropMessages
} from 'react-chat-widget';
import querySearch from "stringquery";
import { buildChatTheme } from '../themes'
import { HostConfigContext, ClientTheme, BotConfig } from '../global/context'
import { createHostConfig } from '../hostConfig'
import * as util from '../utils'
import PAdaptiveCard from './PAdaptiveCard';
import LinkCard from './LinkCard';
import ThemedChatWidget from './ChatWidgetV2.styled'
import 'react-chat-widget/lib/styles.css';
import '../media/styleoverride.css'
import { useMsal } from "@azure/msal-react";
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { ThemeProvider } from '../library/pld-ui';

/// User will not see any message if there is no event after last 24 hrs
const ChatHistoryHoursTheresold = 24

const WidgetWrapper = (props) => {
    React.useEffect(() => {
        // console.log("WidgetWrapper useEffect", props)
    }, [props])
    return (
        <Widget
            title={props.title}
            subtitle={props.subtitle}
            autofocus={props.autofocus}
            handleNewUserMessage={props.handleNewUserMessage}
            showTimeStamp={props.showTimeStamp}
            handleQuickButtonClicked={props.handleQuickButtonClicked}
            fullScreenMode={props.fullScreenMode || false}
            {...props}
        />
    )
}

const alternate_button_sets = [
    {
        origin_list: [
            //"https://jlocal.chatbot.prologisweb.com",
        ],
        buttons: [
            {
                label: "Other",
                value: { action: "other" }
            }
        ]
    },
    {
        origin_list: [
            "https://integration2-hohc4oi-iwoai5mrdsmbc.us-5.magentosite.cloud",
            "https://marketplace.prologisessentials.com",
        ],
        buttons: [
        ]
    }
]

// eslint-disable-next-line
export default function (props) {
    const { instance, inProgress, accounts } = useMsal();
    const [azureaccessToken, setazureaccessToken] = useState(null)
    const webSocket = useRef(null)
    const botConfig = useContext(BotConfig)
    const clientTheme = useContext(ClientTheme)
    const quickActionButtonRef = useRef([]);
    const [endpoint,] = useState(util.getEndpoint())
    const [room,] = useState(util.getRoom() || util.newGuid())
    const [idtoken, setidtoken] = useState(querySearch(window.location.search).idtoken || '')
    const azureToken = props.azureToken || ''
    const additionalButtons = props.additionalButtons || []
    const [widgetState, setWidgetState] = useState(isWidgetOpened())
    const [botConnected, setBotConnected] = useState(false)
    const [quickAccessButton, setQuickAccessButtons] = useState([])

    // var widgetRef = useRef()

    // React to context updates from the parent page

    useEffect(() => {
        if (!azureaccessToken && inProgress === InteractionStatus.None && accounts && accounts.length > 0 ) {

            const accessTokenRequest = {
                scopes: ["user.read"],
                account: accounts[0],
            };
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    setidtoken(accessTokenResponse.idToken)
                    setazureaccessToken(accessTokenResponse.accessToken)
                    try {
                        webSocket.current.close(1000, "reset")
                    } catch (error) {
                        // console.log("unable to close previous socket")
                        // console.log(error)
                    }
                    util.clearRoom()
                    setTimeout(() => {
                        generateWebsocket()
                    }, 2000)
                    


                    
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenPopup(accessTokenRequest)
                            .then(function (accessTokenResponse) {
                                setidtoken(accessTokenResponse.idToken)
                                setazureaccessToken(accessTokenResponse.accessToken)
                                try {
                                    webSocket.current.close(1000, "reset")
                                } catch (error) {
                                    // console.log("unable to close previous socket")
                                    // console.log(error)
                                }
                                util.clearRoom()
                                setTimeout(() => { 
                                    generateWebsocket()
                                }, 2000)
                            })
                            .catch(function (error) {
                                // Acquire token interactive failure
                                console.log(error);
                            });
                    }
                    console.log(error);
                });
        }
    });




    const onEvent = (event) => {
    }

    const onTopEvent = (event) => {
    }

    React.useEffect(() => {
        setQuickButtons(quickAccessButton)
        if (quickAccessButton.length > 0) {
            quickActionButtonRef.current = quickAccessButton
        }

    }, [quickAccessButton])

    const getContext = React.useCallback(() => {
        var context = {}
        context.origin = window.location.origin
        if (props.getContext && typeof props.getContext === 'function') {
            context.parent = props.getContext()
        }
        return context
    }, [props])

    // Types of events from the server
    const postToWebsocket = React.useCallback((body) => {
        try {
            body.context = getContext()
        } catch (error) {
            // console.log("unable to get context for message")
        }
        try {
            webSocket.current.send(JSON.stringify(body))
        } catch (error) {
            // console.log("Error sending to the websocket:", error)
        }
    }, [webSocket, getContext])

    const onExecuteCardAction = React.useCallback((raw_action) => {
        let action = raw_action._propertyBag
        // console.log("onExecuteCardAction", action)
        // console.log("action.type", action.type)
        if (action.type === "Action.OpenUrl") {
            // console.log("action.url", action.url)
            window.open(action.url, "_blank")
        }
        if (action.type === "Action.Submit") {
            let body = {
                "events": [
                    {
                        "type": "data_action",
                        "content": raw_action._processedData,
                        "context": {
                            "local_timestamp": new Date().toISOString()
                        }
                    }
                ]
            }
            // console.log("action", action)
            // console.log("body", body)
            postToWebsocket(body)
        }
    }, [postToWebsocket])

    const handleServerMessage = React.useCallback((chat_event) => {
        // console.log("Server Message:", chat_event)        
        chat_event.events.forEach(event => {
            if (event.type === "text") {
                if (!event.content.startsWith("__")) {
                    renderCustomComponent(
                        LinkCard,
                        {
                            "content": event.content
                        }
                    )
                }
                // else if (!event.content.startsWith("__")) {                   
                //     addResponseMessage(event.content)
                // }
            }
            if (event.type === "link") {
                addLinkSnippet({
                    link: event.content.link,
                    title: event.content.title,
                    target: event.content.target || "_blank"
                })
            }
            if (event.type === "AdaptiveCard") {
                renderCustomComponent(
                    PAdaptiveCard,
                    {
                        card: event,
                        onExecuteAction: onExecuteCardAction
                    }
                )
            }
        })

        /// temp to show hide quick action button at live agent chat start or end
        if (chat_event.events
            && chat_event.events.length > 0
            && chat_event.events[0].body
            && chat_event.events[0].body.length > 0) {
            if (chat_event.events[0].body[0].text.toLowerCase() === 'Starting chat with a Live agent'.toLowerCase()) {
                setQuickAccessButtons([])
            }
            if (chat_event.events[0].body[0].text.toLowerCase() === 'The chat has ended.'.toLowerCase()) {
                setQuickAccessButtons(quickActionButtonRef.current)
            }
        }

    }, [onExecuteCardAction])

    const displayLoader = React.useCallback((loaderVisibility) => {
        /// don't do anything if loader is not enabled at all
        if (!botConfig.enableLoader) return

        const elements = document.getElementsByClassName('loader active')
        const loaderHidden = () => {
            //// accessing the real dom from inside react component is costly operation
            //// but we don't have any api exposed from rcw to check if loader is visible or not

            if (!elements || elements.length === 0) {
                return true
            }

            return false
        }

        if (!loaderVisibility) {
            /// case when trying to hide loader

            /// check if loader exist and active -> hide
            if (!loaderHidden()) {
                toggleMsgLoader()
            }
        }
        else {
            /// case when trying to show loader
            if (loaderHidden) {
                toggleMsgLoader()
                elements[0]?.scrollIntoView({ behavior: 'smooth' });
                setTimeout(() => {
                    displayLoader(false)
                }, 10000);
            }
        }
    }, [botConfig])

    const handleUserMessage = React.useCallback((chat_event) => {
        chat_event.events.forEach(event => {
            if (event.type === "text" && !event.content.startsWith("https://")) {
                addUserMessage(event.content)
            } else if (event.type === "text" && !event.content.startsWith("__")) {

                addUserMessage(event.content)
            }


        })
    }, [])

    const handleChatHistory = React.useCallback((body) => {
        const eventHistory = body.chat_history || []
        if (eventHistory.length > 0) {
            /// clear chat if no message were sent in last 24 hrs

            // sort based on timestamp in ascending order
            eventHistory.sort((a, b) => a.event_timestamp - b.event_timestamp);

            /// take latest message
            const mostRecentMessage = eventHistory[eventHistory.length - 1]
            /// Be Alert, Date.now give timestamp with miliseconds, timestamp saved in database doesn't have it
            const currentTimeStamp = Math.floor((new Date().getTime()) / 1000)
            var diff = (currentTimeStamp - mostRecentMessage.event_timestamp) / 1000;
            diff /= (60 * 60);
            const hrsToLastMessage = diff

            if (hrsToLastMessage >= ChatHistoryHoursTheresold) {
                /// only "return" will also be sufficient but that would not clear redux state of r-c-w, so dropMessage call
                dropMessages()

                if (botConfig.chatMode !== 'live_agent')
                    addResponseMessage("Hi I'm the messaging assistant, what can I help you with?")

                return
            }
        }

        /// What is this guy doing!!
        deleteMessages(1000)
        if (eventHistory.length === 0 && botConfig.chatMode !== 'live_agent') {
            // sendMessageToBackend("help")
            addResponseMessage("Hi I'm the messaging assistant, what can I help you with?")
        }
        else {

            /// What is this guy doing here too!!
            deleteMessages(1000)
            eventHistory.forEach(chat_event => {
                if (chat_event.type === "system_message") {
                    handleServerMessage(chat_event)
                }
                if (chat_event.type === "user_message") {
                    handleUserMessage(chat_event)
                }
            })
            markAllAsRead()
        }
    }, [handleServerMessage, handleUserMessage, botConfig])

    // Websocket components
    const ping = React.useCallback(() => {
        //webSocket.current.send("__ping__")
        postToWebsocket({ "type": "ping" })
        setTimeout(ping, 60000)
    }, [postToWebsocket])

    const onWebSocketConnect = React.useCallback((event) => {
        // console.log("Chatbot Connected")
        displayLoader(false)
        ping()
        // Note you can't use postToWebsocket here because it doesn't think
        // it has initialized yet
        event.target.send(JSON.stringify({
            "auth_request": {
                "tokens": [idtoken]
            }
        }))
        if (idtoken !== '') {
            event.target.send(JSON.stringify({
                "auth_request": {
                    "tokens": [idtoken]
                }
            }))
        }
        setBotConnected(true)
    }, [idtoken, ping, displayLoader])

    const onWebSocketDisconnect = (event) => {
        // console.log("Disconnected")
        // console.log(event)
        if (![1000, 1006].includes(event.code)) {
            addResponseMessage("Connection closed due to innactivity.")
        }
        setQuickAccessButtons([
            {
                label: "Rejoin Room",
                value: { action: "rejoinroom" }
            }
        ])
    }

    const handleresolvedticketbutton = (data) => {
        if (data.events.length > 0) {
            let button = [{
                label: "Resolved Tickets",
                value: { action: "resolvedticket" }
            }]
            if (data.events && data.events[0] && data.events[0].body && data.events[0].body[0] && data.events[0].body[0].text.toLowerCase() === 'The chat has ended.'.toLowerCase()) {
                setQuickAccessButtons(quickActionButtonRef.current.filter(e => e.value.action !== "resolvedticket"))
            }
            else if (quickActionButtonRef.current.filter(e => e.value.action === "resolvedticket").length === 0) {
                setQuickAccessButtons(button.concat(quickActionButtonRef.current))
            }
        }



    }

    const onWebSocketMessage = React.useCallback((event) => {
        let data = JSON.parse(event.data)
        if (data.message_type === "system_message") {
            handleresolvedticketbutton(data)
            handleServerMessage(data)
            displayLoader(false)
        }
        if (data.message_type === "user_message") {
            // handleUserMessage(data)
        }
        if (data.message_type === "chat_history") {
            handleChatHistory(data)
        }
    }, [handleServerMessage, handleChatHistory, displayLoader])



    const generateWebsocket = () => {
        
        displayLoader(true)
        var parameters = "?version=1"
        parameters += azureToken ? "&azuretoken=" + azureToken : ""
        // parameters += idtoken ? "&idtoken=" + idtoken : ""
        parameters += room ? "&room=" + room.name : ""
        webSocket.current = new WebSocket("wss://" + endpoint + "/websocket/" + parameters) // + "&idtoken=" + idtoken)
        webSocket.current.onopen = (event) => onWebSocketConnect(event)
        webSocket.current.onclose = (event) => onWebSocketDisconnect(event)
        webSocket.current.onmessage = (event) => onWebSocketMessage(event)
        setDefaultButtons()
    }

    // Interacton with the back end
    const sendMessageToBackend = React.useCallback((message, type = 'text') => {
        let body = {
            "events": [
                {
                    "type": type,
                    "content": message
                }
            ]
        }
        postToWebsocket(body)
        setTimeout(() => {
            displayLoader(true)
        }, 700);
    }, [postToWebsocket, displayLoader])

    // How to react to user actions
    const handleNewUserMessage = React.useCallback((event) => {
        sendMessageToBackend(event)
    }, [sendMessageToBackend])

    const handleQuickButtonClicked = (event) => {
        // console.log("quick button clicked")
        // console.log(event)
        for (let button of additionalButtons) {
            if (button.value.action === event.action) {
                button.onClick()
            }
        }
        if (event.action !== undefined) {
            if (event.action === "rejoinroom") {
                generateWebsocket()
            }
            if (event.action === "debug") {
                handleDebug()
            }
            if (event.action === "clearchat") {
                handleClearChat()
            }
            if (event.action === "reset") {
                handleReset()
            }
            if (event.action === "help") {
                sendMessageToBackend("help")
            }
            if (event.action === "resolvedticket") {
                sendMessageToBackend("Resolved Ticket")
            }
            if (event.action === "other") {
                sendMessageToBackend("other")
            }
            if (event.action === "agent") {
                sendMessageToBackend("live agent")
            }
            if (event.action === "order_hardware") {
                sendMessageToBackend("order hardware")
            }
            if (event.action === "request_access") {
                sendMessageToBackend("request access")
            }
            if (event.action === "change_password") {
                sendMessageToBackend("password")
                // window.open(
                //     "https://passwordreset.prologis.com"
                //     , "_blank")
            }
            if (event.action === "open_ticket") {
                window.open(
                    "https://prologis.service-now.com/nav_to.do?uri=%2Fincident.do%3Fsys_id%3D-1%26sysparm_query%3Dactive%3Dtrue%26sysparm_stack%3Dincident_list.do%3Fsysparm_query%3Dactive%3Dtrue"
                    , "_blank")
            }
            if (event.action === "support_team_phone_number") {
                window.open(
                    "https://prologis.sharepoint.com/sites/IT/SitePages/IT-Help!.aspx"
                    , "_blank")
            }
        }
    }
    const setDefaultButtons = () => {
        var buttons = [
            {
                label: "Chat with an agent",
                value: { action: "agent" }
            },
            //{
            //    label:"Start Over",
            //    value:{action: "help"}
            //},
            {
                label: "Open a ticket",
                value: { action: "open_ticket" }
            },
            {
                label: "Support team phone number",
                value: { action: "support_team_phone_number" }
            },
            {
                label: "Order Hardware",
                value: { action: "order_hardware" }
            },
            {
                label: "Change Password",
                value: { action: "change_password" }
            },
            {
                label: "Request Access",
                value: { action: "request_access" }
            },
            {
                label: "Other",
                value: { action: "other" }
            }
        ].concat(additionalButtons)

        const current_origin = window.location.origin
        for (let alternate_set of alternate_button_sets) {
            for (let altered_origin of alternate_set.origin_list) {
                if (current_origin === altered_origin) {
                    buttons = alternate_set.buttons
                }
            }
        }

        setQuickAccessButtons(buttons)
    }

    // exploring
    const handleDebug = () => {
        // console.log(window)
    }

    const handleReset = () => {
        try {
            webSocket.current.close(1000, "reset")
        } catch (error) {
            // console.log("unable to close previous socket")
            // console.log(error)
        }
        util.clearRoom()
        setTimeout(() => {
            // console.log("waiting")
        }, 2000)
        generateWebsocket()
        deleteMessages(1000)
    }

    const handleClearChat = () => {
        deleteMessages(1000)
    }

    // Run Once on final load
    useEffect(() => {
        generateWebsocket()
        try {
            window.addEventListener("message", onEvent)
            window.top.addEventListener("message", onTopEvent)
        } catch (error) {
            // console.log("unable to add event listener", error)
        }

        if (props.defaultOpen === true) {
            toggleWidget()
        }

        window.top.postMessage("da-bot is here!")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleToggle = (toggleState) => {
        setWidgetState(toggleState)
    }

    useEffect(() => {
        if (botConfig.chatMode === 'live_agent' && widgetState && botConnected) {
            // console.log("chatMode Initiate", botConfig.chatMode)
            sendMessageToBackend("__chat_with_available_agent")
        }
    }, [widgetState, botConnected, botConfig.chatMode, sendMessageToBackend])

    const chatTheme = buildChatTheme(clientTheme)
    return (
        <ThemeProvider theme={chatTheme}>
            <HostConfigContext.Provider value={createHostConfig(chatTheme)}>
                <ThemedChatWidget
                    fullScreenMode={props.fullScreenMode || false}
                    headerVisibility={props.headerVisibility}
                    stackQuickactionButtons={botConfig.quickButtonsStacked}
                >
                    <WidgetWrapper
                        title="PROLOGIS CHAT"
                        subtitle=""
                        autofocus={true}
                        handleNewUserMessage={handleNewUserMessage}
                        showTimeStamp={false}
                        handleQuickButtonClicked={handleQuickButtonClicked}
                        handleToggle={handleToggle}
                        senderPlaceHolder={'So, what can I help you with?'}
                        {...props}
                    />
                </ThemedChatWidget>
            </HostConfigContext.Provider>
        </ThemeProvider>
    )
}
